import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class AlertsSuccessComponent implements OnInit {

  constructor() {

  }

  @Input() show: boolean = false;
  @Input() message: string = '';

  ngOnInit() {

  }
}

