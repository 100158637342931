import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class OrderService {

  private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  private options = new RequestOptions({ headers: this.headers });
  // private multiHeaders = new Headers({ 'Accept': 'application/json' });
  // private multiOptions = new RequestOptions({ headers: this.multiHeaders });
  private base = '/api/order';
  public urls = {
    'getOrders': `${this.base}/getOrders`,
    'getAdminOrders': `${this.base}/getAdminOrders`,
    'getSupervisorOrders': `${this.base}/getSupervisorOrders`,
    'getCostCenterOrders': `${this.base}/getCostCenterOrders`,
    'insert': `${this.base}/insert`,
    'updateStatus': `${this.base}/updateStatus`,
    'updateOrder': `${this.base}/updateOrder`,
    'orderedOrder': `${this.base}/orderedOrder`,
    'approvedOrder': `${this.base}/approvedOrder`,
    'delete': `${this.base}/delete`,
    'undelete': `${this.base}/undelete`,

  };

  constructor(private http: Http) {

  };

  getOrders(): Observable<any> {
    return this.http.get(this.urls.getOrders, {}).map(res => res.json());
  }

  getAdminOrders(): Observable<any> {
    return this.http.get(this.urls.getAdminOrders, {}).map(res => res.json());
  }

  getSupervisorOrders(user): Observable<any> {
    return this.http.get(this.urls.getSupervisorOrders, {'params': user}).map(res => res.json());
  }

  getCostCenterOrders(user): Observable<any> {
    return this.http.get(this.urls.getCostCenterOrders, {'params': user}).map(res => res.json());
  }

  updateOrder(order): Observable<any> {
    return this.http.post(this.urls.updateOrder, order, this.options).map(res => res.json());
  }

  updateStatus(order): Observable<any> {
    return this.http.post(this.urls.updateStatus, order, this.options).map(res => res.json());
  }

  orderedOrder(order): Observable<any> {
    return this.http.post(this.urls.orderedOrder, order, this.options).map(res => res.json());
  }

  approvedOrder(order): Observable<any> {
    return this.http.post(this.urls.approvedOrder, order, this.options).map(res => res.json());
  }

  delete(order): Observable<any> {
    return this.http.delete(this.urls.delete, {'params': order}).map(res => res.json());
  };

  undelete(order): Observable<any> {
    return this.http.post(this.urls.undelete, {'params': order}).map(res => res.json());
  };

  insert(order): Observable<any> {
    return this.http.post(this.urls.insert, {'params': order}, this.options).map(res => res.json());
  }

}
