import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-initial-login',
  templateUrl: './initial-login.component.html',
  styleUrls: ['./initial-login.component.scss']
})
export class InitialLoginComponent implements OnInit {

  constructor(private auth: AuthService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  loginForm: FormGroup;
  username = new FormControl('', [Validators.required,
                                  Validators.minLength(2),
                                  Validators.maxLength(100)]);

  email = new FormControl('', [Validators.required,
                               Validators.minLength(3),
                               Validators.maxLength(100)]);

  password = new FormControl('', [Validators.required,
                                  Validators.minLength(5)]);

  newpassword = new FormControl('', [Validators.required,
                                  Validators.minLength(5)]);               
                                  
  confirmpassword = new FormControl('', [Validators.required,
                                  Validators.minLength(5)]);                                     

  showError = false;
  error: String;

  ngOnInit() {
    if (this.auth.loggedIn) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.formBuilder.group({
      username: this.email,
      email: this.email,
      password: this.password,
      newpassword: this.newpassword,
      confirmpassword: this.confirmpassword
    });
  }

  setClassEmail() {
    return { 'has-danger': !this.email.pristine && !this.email.valid };
  }

  setClassPassword() {
    return { 'has-danger': !this.password.pristine && !this.password.valid };
  }

  setClassNewPassword() {
    return { 'has-danger': !this.newpassword.pristine && !this.newpassword.valid };
  }

  login() {
    this.error = '';
    this.showError = false;

    if ( this.password.value != this.newpassword.value ) {
      if ( this.newpassword.value == this.confirmpassword.value ) {
        this.auth.initialLogin(this.loginForm.value).subscribe(
          res => {
            this.router.navigate(['/home']);
          },
          error => {
            this.error = 'Invalid login. Please use the default password you received in the welcome email.';
            this.showError = true;
            this.password.reset(); 
            this.newpassword.reset(); 
            this.confirmpassword.reset();             
            this.hideMessages(); 
          }
        );
      } else {
        this.error = "New passwords don't match";
        this.showError = true;
        this.newpassword.reset(); 
        this.confirmpassword.reset(); 
        this.hideMessages(); 
      }
    } else {
      this.error = "Please choose a new password";
      this.showError = true;
      this.hideMessages(); 
    }
  }

  hideMessages() {
    setTimeout(() => { 
      this.showError = false;
      this.error = '';
    }, 3000);
  };   

}
