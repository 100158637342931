import { Component, OnInit, Input} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ClrDatagridSortOrder } from "@clr/angular";
import { AuthService } from '../user/services/auth.service';
import { UserService } from '../user/services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';


@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
    constructor(
                private userService: UserService,
                private auth: AuthService) {
        this.currentUser = auth.currentUser;
    }

    manageUsers: boolean = false;
    createUsers: boolean = false;
    manageOrders: boolean = true;

    currentUser: any = {};
    isLoading = true;
    moment = moment;
    a: any = [{}]
    expand: boolean = false;
    processing = false;

    ngOnInit() {

    }

    toggleAdministration(option) {
      this.createUsers = false;
      this.manageUsers = false;
      this.manageOrders = false;

      switch(option) {
        case "users": {
          this.manageUsers = true;
          break;
        }
        case "createuser": {
          this.createUsers = true;
          break;
        }
        case "manageorders": {
          this.manageOrders = true;
          break;
        }
      }
    }

    toggle() {
        if (this.expand) {
            this.expand = false;
        } else {
            this.expand = true;
        }
    };


    labelContent(e: any): string {
      return `${ e.category } ${e.value}`;
    }

    labelColor(e){
      return "#ffffff"
    }

    sideNav() {
        this.manageUsers = false;
        this.createUsers = false;
    }

}
