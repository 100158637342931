import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AUTH_TOKEN } from '../jwt/config';
import { UserService } from './user.service';
import { IUser } from '../../lib/iuser';

import * as _ from 'lodash';
import * as moment from 'moment';

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_USER = 'user';

@Injectable()
export class AuthService {
  loggedIn = false;
  isAdmin = false;
  allow_reporting = false;

  currentUser: IUser = { };
  settingsStorageId = 'reaching-settings';
  private _expireTime = '';
  private _authCheckIntervalId = null;

  constructor(private userService: UserService,
              private router: Router,
              private jwtHelperService: JwtHelperService) {

    const token = localStorage.getItem(AUTH_TOKEN);
    if (!_.isUndefined(token)) {
      if (token) {
        const decodedUser = this.decodeUserFromToken(token);
        if (_.isEmpty(decodedUser)) {
          this._sendToLogout();
        } else {
          this.setCurrentUser(decodedUser);
          if (!decodedUser.id) {
            this._sendToLogout();
          } else {
            this.userService.verify(decodedUser.id).subscribe(
              res => {
                if (res.expire) {
                  this._expireTime = res.expire;
                  this._setAuthCheck();
                } else {
                  this._sendToLogout();
                }
              },
              error => {
                console.log(error);
                this._sendToLogout();
              }
            );
          }
        }
      }
    }
  }

  private _setAuthCheck() {
    console.log("==> Session Expire", this._expireTime);
    console.log("==> Computer Time", moment().format("YYYY-MM-DD HH:mm:ss"));

    const fromDate = moment.utc();
    const toDate = moment(this._expireTime);
    const hourDiff = toDate.diff(fromDate, 'hours');
    const minuteDiff = toDate.diff(fromDate, 'minutes');
    const hourDuration = Math.floor(minuteDiff / 60);
    const minuteDuration = minuteDiff % 60;
    console.log(`==> Session Expires in ${hourDuration} hours and ${minuteDuration} minutes`);

    this._authCheckIntervalId = setInterval(() => {
      const now = moment();
      const expire = moment(this._expireTime);

      const date1 = new Date(expire.format("YYYY-MM-DD HH:mm:ss"));
      const date2 = new Date(now.format("YYYY-MM-DD HH:mm:ss"));

      if (date2 > date1 || !this.loggedIn) {
        clearInterval(this._authCheckIntervalId);
        if (this._authCheckIntervalId) {
          console.log("==> Expired");
        }
        if (this.loggedIn) {
          this._sendToLogout();
        }
      }
    }, 3000);
  };

  _sendToLogout() {
    this.router.navigate(['/logout']);
  };

  login(emailAndPassword) {
    return this.userService.login(emailAndPassword).map(
      res => {
        console.log("==> LOGIN", res);
        if (res.expire) {
          this._expireTime = res.expire;
        }
        localStorage.setItem(AUTH_TOKEN, res.token);
        const decodedUser = this.decodeUserFromToken(res.token);

        this.setCurrentUser(decodedUser);
        this._setAuthCheck();

        return this.loggedIn;
      }
    );
  }

  initialLogin(emailAndPassword) {
    return this.userService.initialLogin(emailAndPassword).map(res => res.json()).map(
      res => {
        localStorage.setItem(AUTH_TOKEN, res.token);
        if (res.expire) {
          this._expireTime = res.expire;
        }
        const decodedUser = this.decodeUserFromToken(res.token);
        this.setCurrentUser(decodedUser);
        this._setAuthCheck();

        return this.loggedIn;
      }
    );
  };

  logout() {
    return this.userService.logout().map(res => res.json()).map(
      res => {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(this.settingsStorageId);
        this.loggedIn = false;
        this.isAdmin = false;
        this.allow_reporting = false;
        this.currentUser = { };

        return this.loggedIn;
      }
    );
  }

  decodeUserFromToken(token) {
    try {
      return this.jwtHelperService.decodeToken(token).user;
    } catch(err) {
      return null;
    }
  }

  setCurrentUser(decodedUser) {
    this.loggedIn = true;

    this.currentUser.dw_user_key = decodedUser.dw_user_key || null;
    this.currentUser._id = decodedUser.id;
    this.currentUser.username = decodedUser.username;
    this.currentUser.email = decodedUser.username;
    this.currentUser.role = decodedUser.role;
    this.currentUser.company = decodedUser.company;
    decodedUser.role === 'admin' ? this.isAdmin = true : this.isAdmin = false;
    this.currentUser.allow_reporting = decodedUser.allow_reporting;
    this.currentUser.cost_center = decodedUser.cost_center;
    // console.log(this.currentUser)
    // console.log(decodedUser)
    delete decodedUser.role;

    this.getCurrentUserSettings();

  }

  getCurrentUserSettings( ) {
    const storage = localStorage.getItem(this.settingsStorageId);
    try {
      if (!_.isUndefined(storage)) {
        const settings = JSON.parse(storage);

      }
    } catch(err) {

    }
  }

  setCurrentUserSettings( data ) {
    if (!_.isUndefined(data)) {
      localStorage.setItem(this.settingsStorageId, JSON.stringify(data));
    }
  }

  isLoggedIn() {
    //return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    /*
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
    */
  };

}
