import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../user/services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  createAccountForm: FormGroup;
  username = new FormControl('', [Validators.required,
                                  Validators.minLength(2),
                                  Validators.maxLength(30),
                                  Validators.pattern('[a-zA-Z0-9_-\\s]*')]);

  email = new FormControl('', [Validators.required,
                               Validators.minLength(3),
                               Validators.maxLength(100)]);

  firstname = new FormControl('', [Validators.required,
                                   Validators.minLength(2),
                                   Validators.maxLength(100)]);

  lastname = new FormControl('', [Validators.required,
                                  Validators.minLength(2),
                                  Validators.maxLength(100)]);

  role = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  company = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  title = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  cost_center = new FormControl('', [Validators.maxLength(24)]);

  processing = false;

  selectedCompany: any;
  selectedRole: any;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.createAccountForm = this.formBuilder.group({
      username: this.email,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      title: this.title,
      selectedRole: this.selectedRole,
      company: this.selectedCompany,
      cost_center: this.cost_center
    });
  };

  showError = false;
  error: String;

  companySelected(){
    // console.log(this.selectedCompany)
  }

  roleSelected(){
    // console.log(this.selectedRole)
  }

  save() {
    this.error = '';
    this.showError = false;
    this.processing = true;
    this.userService.request(this.createAccountForm.value).subscribe(
      res => {
        this.router.navigate(['/login']);
      },
      error => {
        this.error = error.status == "400" ? 'Login is already in use' : (_.isEmpty(error._body) ? 'Unknown error' : JSON.parse(error._body));
        this.showError = true;
        this.processing = false;
      }
    );
  }
}
