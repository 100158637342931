import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';

import { CoreModule } from '../core/core.module';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule }       from '../shared/shared.module';

import { NewOrderComponent } from '../new-order/new-order.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordResetComponent } from './password/reset/reset.component';
import { RequestComponent } from './request/request.component';
import { UserAccountComponent } from './account/account.component';

import { AuthService } from './services/auth.service';
import { PasswordService } from './services/password.service';
import { UserService } from './services/user.service';
import { AuthGuardLogin } from './services/auth-guard-login.service';
import { AuthGuardAdmin } from './services/auth-guard-admin.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InitialLoginComponent } from './initial-login/initial-login.component';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { JwtConfig } from './jwt/config';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CoreModule,
        ClarityModule,
        UserRoutingModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: JwtConfig
            }
        })
    ],
    exports:      [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserAccountComponent
    ],
    declarations: [
        NewOrderComponent,
        LoginComponent,
        LogoutComponent,
        PasswordResetComponent,
        RequestComponent,
        UserAccountComponent,
        ChangePasswordComponent,
        InitialLoginComponent
    ],
    providers: [
        AuthService,
        AuthGuardLogin,
        AuthGuardAdmin,
        PasswordService,
        UserService
    ]
})
export class UserModule { }
