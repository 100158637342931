import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardAdmin implements CanActivate {

  constructor(public auth: AuthService, private router: Router) {}

  canActivate() {
    if (!this.auth.loggedIn) {
      this.router.navigate(['/login']);
    } else if (!this.auth.isAdmin) {
      this.router.navigate(['/home']);
    }
    return this.auth.isAdmin;
  }

}
