import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuardLogin } from '../user/services/auth-guard-login.service';

import { HomeComponent } from '../home/home.component';
import { GettingStartedVideoComponent } from '../getting-started-video/getting-started-video.component';
import { OrdersComponent } from '../orders/orders.component';
import { OrderReportingComponent } from '../reporting/order-reporting.component';
import { SamisReportingComponent } from '../samis-reporting/samis-reporting.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';


import { NotFoundComponent } from './not-found/not-found.component';

import { OrderService } from './services/order.service';
import { ReportingService } from './services/reporting.service';
import { LogService } from './services/log.service';
import { CompaniesService } from './services/companies.service';


@NgModule({
    imports: [
        CommonModule,
        CoreRoutingModule,
        ExcelExportModule,
        SharedModule,
    ],
    declarations: [
        HomeComponent,
        GettingStartedVideoComponent,
        OrdersComponent,
        OrderReportingComponent,
        SamisReportingComponent,
        NotFoundComponent,
     ],
    exports: [
        ClarityModule,
    ],
    providers: [
        AuthGuardLogin,
        OrderService,
        ReportingService,
        LogService,
        CompaniesService,
    ]
})
export class CoreModule {
    constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
