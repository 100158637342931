import { Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-user-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class UserAccountComponent implements OnChanges, OnInit {
  @ViewChild('accountForm') form;
  @Input() user: any = {};
  @Input() isModal: boolean = false;
  @Input() currency: string;

  navTabName = 'account-settings';
  currentUser: any = {};
  processing = false;
  isLoading = true;
  showSuccess = false;
  showError = false;
  message = '';
  userPassword = '';
  conversion: number;
  pricing: string;
  viewreporting: boolean = false;

  constructor(public auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService) {

              this.currentUser = auth.currentUser;
  };

  ngOnInit() {
    this.route.params.subscribe( params => {
      if (!_.isUndefined(params['id']) && !_.isEmpty(_.trim(params['id']))) {
        if (this.currentUser._id != params['id']) {
          this.router.navigate(['/login']);
        } else {
          this.getUser(params['id']);
        }
      }
      this.getUser(this.user['id'])
    });

    this.currency = this.pricing;
  }

  ngOnChanges(changes: any) {
    if (changes.user) {
      if (changes.user.previousValue != changes.user.currentValue && _.size(changes.user.currentValue) > 0) {
        if (!this.user.password) {
          this.user.password = null;
        }
        this.isLoading = false;
      }
    }

  };

  getUser(id) {
    if (!_.isUndefined(id)) {
      this.userService.getUser({ id }).subscribe(
        data => {
          this.user = Object.assign({}, data);
          // console.log(this.user)
        },
        error => {
          this.message = 'User Information could not be loaded.';
          this.showError = true;
        },
        () => this.isLoading = false
      );
    }
  };

  allowChangePassword( ) {
    return !this.isModal //&& this.auth.allowAccess('user.change password');
  };

  save(user) {
    this.processing = true;
    const data = Object.assign({}, user);
    if (!(this.auth.isAdmin && this.isModal)) {
      delete(data['password']);
    };
    this.userService.editUser(user).subscribe(
      data => {
        this.message = 'User Information has been successfully updated.';
        this.showSuccess = true;
        this.form['form'].markAsPristine();
      },
      error => {
        this.message = 'User Information could not be updated.';
        this.showError = true;
        this.processing = false;
      },
      () => {
        this.processing = false;
        this.hideMessages();
      }
    );
  }

  hideMessages() {
    setTimeout(() => {
      this.processing = false;
      this.showSuccess = false;
      this.showError = false;
      this.message = '';
    }, 3000);
  };
}
