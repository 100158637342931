import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { AuthService } from '../../user/services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../user/services/user.service';
import {ClrDatagridSortOrder} from '@clr/angular';
import { CompaniesService } from '../../core/services/companies.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'app-admin-account',
    templateUrl: './admin-account.component.html',
    styleUrls: ['./admin-account.component.scss']
})
export class AdminUserAccountComponent implements OnInit {
    @ViewChild('approveForm') form;
    @Input() manageUsers = false;

    descSort = ClrDatagridSortOrder.DESC;
    users: any = {};
    theme: string;
    sessionUserHistoryId = null;
    isLoading = true;
    currentUser: any = {};
    approvalUser: any = {};
    requestedCompany = "";
    moment = moment;
    message = '';
    companies = [];
    companySelected: boolean = false;
    showSuccess: boolean = false;
    showError: boolean = false;

    modals = {
        edit: {
            opened: false,
            processing: false
        },
        create: {
            opened: false,
            processing: false
        },
        delete: {
            opened: false,
            processing: false
        },
        approve: {
            opened: false,
            processing: false
        },
        success: {
            opened: false
        },
        failure: {
            opened: false
        },
        history: {
            opened: false,
            processing: false
        }
    };

    constructor(public auth: AuthService,
                private userService: UserService,
                private companiesService: CompaniesService) {

    }

    ngOnInit() {
        this.getUsers();
        this.getCompanies();
    };

    ngOnChanges(){
      this.getUsers();
      this.getCompanies();
    }

    getUsers() {
        this.users = [];
        this.userService.getUsers().subscribe(
            data => {
                this.users = data;
            },
            error => {
                console.log(error);
            },
            () => this.isLoading = false
        );
    };

    getCompanies(){
      this.companies = [];
      this.companiesService.getCompanies().subscribe(
        (data) => {
          this.companies = data;
      });
    }

    approveModalOpen(user) {
        this.modals.approve.opened = true;
        this.approvalUser = Object.assign({}, user);
        this.requestedCompany = user.company;
    };

    saveApprove(user) {
      //first save updates
        this.userService.editUser(user).subscribe(
          data => {
            this.approve(user)
          },
          error => {
            this.message = 'User Information could not be updated and user not approved.';
          }
        );
    }

    approve(user) {
        const params = {
            id: user.id,
            approved_by: this.auth.currentUser.email
        };
        this.userService.approve(params).subscribe(
            data => {
                const index = _.findIndex(this.users, { id: params.id });
                if (index != -1) {
                    if (data._body) {
                        this.users[index] = JSON.parse(data._body);
                        this.message = '' + this.currentUser['email'] + ' was successfully approved. An email was sent with their password.';
                        // this.modals.success.opened = true;
                    }
                }
            this.modals.approve.opened = false;
            },
            error => {
                this.message = JSON.parse(error._body);
            }
        );
    };

    editModalOpen(user) {
        this.modals.edit.opened = true;
        this.currentUser = Object.assign({}, user);
    };

    edit() {
        this.modals.edit.processing = true;
        this.userService.editUser(this.currentUser).subscribe(
            data => {
                const index = _.findIndex(this.users, { id: this.currentUser['id'] });
                if (index != -1) {
                    this.users[index] = this.currentUser;
                }
                this.modals.edit.processing = false;
            },
            error => {
                console.log(error);
                this.modals.edit.processing = false;
            },
            () => this.modals.edit.processing = false
        );
    };

    deleteModalOpen(user) {
        this.modals.delete.opened = true;
        this.currentUser = Object.assign({}, user);
    };

    delete() {
        this.modals.delete.processing = true;
        this.userService.deleteUser(this.currentUser).subscribe(
            data => {
                this.modals.delete.opened = false;
                this.message = '' + this.currentUser['email'] + ' was successfully deleted.';
                this.modals.success.opened = true;

                const index = _.findIndex(this.users, { id: this.currentUser['id'] });
                if (index != -1) {
                    this.users.splice(index, 1);
                }
            },
            error => {
                this.modals.delete.opened = false;
                this.message = '' + this.currentUser['email'] + ' could not be deleted.';
                this.modals.failure.opened = true;
            },
            () => this.modals.delete.processing = false
        );
    };

}
