import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from '../../user/services/auth.service';
import { UserService } from '../../user/services/user.service';
import { OrderService } from '../../core/services/order.service';
import { formatDate, DatePipe } from '@angular/common';
import { LogService } from '../../core/services/log.service';

import * as _ from 'lodash';

@Component({
    styleUrls: ['./admin-orders.component.scss'],
    templateUrl: './admin-orders.component.html',
    host: { '(window: resize)': 'onResize($event)' },
})
export class AdminOrdersComponent {

  orders = [];
  currentUser:any
  ordered_selected: boolean = false;
  editModal: boolean = false;
  order_process:any;
  booked_delivery_time = "";
  booked_delivery_date = "";
  booked_delivery_instructions = "";

  allorders = [];
  pendingOrders = [];
  approvedOrders = [];
  orderedOrders = [];
  deniedOrders = [];
  deletedOrders = [];
  orderLog = [];
  new_note: any = "";
  orderNotes = [];
  selectedOrder: any = {};
  order_denied_text: any = "";
  custom_ordertime: any = "";
  custom_disabled: boolean = true;

  all: boolean = false;
  pending: boolean = false;
  approved: boolean = false;
  ordered: boolean = false;
  denied: boolean = false;
  viewLogModal: boolean = false;
  createNoteModal: boolean = false;
  viewNotesModal: boolean = false;
  newNoteNotification: boolean = false;
  deleteOrderModal: boolean = false;
  editOrderModal: boolean = false;
  orderDeniedModal: boolean = false;

    constructor(
      private orderService: OrderService,
      private logService: LogService,
      public auth: AuthService,
      private datePipe: DatePipe
      ) {
        this.currentUser = auth.currentUser;
    }

    ngOnInit() {
      this.getOrderData();
      // console.log(this.currentUser.username)
    };

    sortOrderByDate(direction){
      // this.sortOrder == 'desc' ? this.sortOrder = 'asc' : this.sortOrder = 'desc';
      let sortedOrders = [];
      sortedOrders = _.orderBy(this.orders, ['create_date'], [direction]);
      this.orders = sortedOrders;

    }

    getOrderData(){
      this.orders = [];
      this.allorders = [];
      this.pendingOrders = [];
      this.approvedOrders = [];
      this.orderedOrders = [];
      this.deniedOrders = [];
      this.deletedOrders = [];
      this.order_process = [];
      this.booked_delivery_time = "";
      this.booked_delivery_date = "";
      this.booked_delivery_instructions = "";

      this.orderService.getAdminOrders().subscribe(
        (data) => {
          this.allorders = data;
          for (let order of this.allorders) {
            if (order.status == "Pending"){this.pendingOrders.push(order)}
            if (order.status == "Approved"){this.approvedOrders.push(order)};
            if (order.status == "Ordered"){this.orderedOrders.push(order)};
            if (order.status == "Denied"){this.deniedOrders.push(order)};
            if (order.deleted == "Y"){this.deletedOrders.push(order)};
          };
          // we show pending by default
          this.filterOrders('Pending');
      });
    }

    viewLog(order) {
      // console.log("View Log for Order ID: ", order.order_id)
      const params = {
          order_id: order.order_id
      };
      this.logService.getLog(params).subscribe(
        (data) => {
          this.orderLog = data;
          }
      );
      this.viewLogModal = true;
    }

    orderDenied (order) {
      this.order_denied_text = "";
      this.orderDeniedModal = true;
      this.selectedOrder = order;
    }



    sendOrderDeniedMail () {

      this.updateOrder(this.selectedOrder, 'Denied')
      // clear selectedOrder
      this.selectedOrder = {};
    }

    viewNotes(order) {
      this.selectedOrder = order;
      this.orderLog = [];
      this.orderNotes = []
      const params = {
          order_id: order.order_id
      };
      this.logService.getLog(params).subscribe(
        (data) => {
            this.orderLog = data;
            for (let entry of this.orderLog) {
                if (entry.log_entry_type == "note") {
                  this.orderNotes.push(entry);
                }
            }
         }
      );
      this.viewNotesModal = true;
    }

    clearAlert(order) {
      this.selectedOrder = order;
      this.new_note = "New note alert cleared";
      this.sendNote();
    }

    sendNote() {
      const params = {
          order_id: this.selectedOrder.order_id,
          log_entry: this.new_note,
          log_entry_type: "note",
          order_creator: this.selectedOrder.created_by
      };
      this.logService.insert(params).subscribe(
        (data) => {
          }
      );
      this.new_note = [];
      this.getOrderData();
      this.viewNotesModal = false;
      this.createNoteModal = false;
    }

    createNote(order) {
      this.selectedOrder = order;
      this.createNoteModal = true;
    }

    editOrder(order) {
      // console.log("Edit Order ID: ", order)
      this.selectedOrder = order;
      // console.log(this.selectedOrder)
      this.editOrderModal = true;
    }

    editOrderFinished() {

      this.orderService.updateOrder(this.selectedOrder).subscribe(
        (data) => {
            const logparams = {
                order_id: this.selectedOrder.order_id,
                log_entry: "Edit Order Completed",
                log_entry_type: "log"
            };
            this.logService.insert(logparams).subscribe(
              (data) => {
                }
            );
            this.getOrderData();
            this.selectedOrder = {};
      });
    }

    undeleteOrder(order){
      this.orderService.undelete(order).subscribe(
          data => {
            // this.getOrderData();
            this.updateOrder(order, 'Pending')
            this.selectedOrder = {};
          },
          error => {
            this.selectedOrder = {};
          }
      );
    }

    cancelEditOrder() {
      this.selectedOrder = {};
    }

    deleteOrder(order) {
      this.selectedOrder = order;
      this.deleteOrderModal = true;
      // console.log("Delete Order ID: ", order)
    }

    confirmDeleteOrder() {

      const params = {
          order_id: this.selectedOrder.order_id
      };
      let order = this.selectedOrder;
      this.orderService.delete(params).subscribe(
          data => {
            this.deleteOrderModal = false;
            // this.getOrderData();
            this.updateOrder(order, 'Deleted')
            this.selectedOrder = {};
          },
          error => {
            this.deleteOrderModal = false;
            this.selectedOrder = {};
          }
      );
    }

    filterOrders(filter){
      this.all = false;
      this.pending = false;
      this.approved = false;
      this.ordered = false;
      this.denied = false;
      switch(filter){
        case 'All': {
          this.orders = this.allorders;
          this.all = true;
          break;
        }
        case "Pending": {
          this.orders = this.pendingOrders;
          this.sortOrderByDate('asc');
          this.pending = true;
          break;
        }
        case "Approved": {
          this.orders = this.approvedOrders;
          this.sortOrderByDate('asc');
          this.approved = true;
          break;
        }
        case "Ordered": {
          this.orders = this.orderedOrders;
          this.ordered = true;
          break;
        }
        case "Denied": {
          this.orders = this.deniedOrders;
          this.denied = true;
          break;
        }
        case "Deleted": {
          this.orders = this.deletedOrders;
          this.denied = true;
          break;
        }
      }
    }

    editModalOpen(order){
      this.editModal = true;
      console.log(order)
    }

    ngOnChanges(changes: any) {

    }

    processOrder(order){
      this.order_process = order;
      this.booked_delivery_instructions = order.delivinstructions;
      this.ordered_selected = true;
    }

    resetApprovalModal() {
      this.booked_delivery_time = "";
      this.booked_delivery_date = "";
      this.booked_delivery_instructions = "";
    }

    saveOrder(order){
      this.orderedOrder(order)
      this.resetApprovalModal()
    }

    orderedOrder(order) {
      let formattedbookeddate = this.datePipe.transform(this.booked_delivery_date, 'fullDate');
      const params = {
        order_id: order.order_id,
        new_status: "Ordered",
        booked_delivery_date: this.booked_delivery_date,
        booked_delivery_time: this.booked_delivery_time,
        booked_delivery_instructions: this.booked_delivery_instructions,
        mail_booked_delivery_date: formattedbookeddate,
        recipient: order.recipient,
        address1: order.address1,
        address2: order.address2,
        suburb: order.suburb,
        postcode: order.postcode,
        selectedpack: order.selected_pack == 'singleparent' ? "Single Parent Pack" : order.selected_pack == 'pensioner' ? "Pensioner Pack" : order.selected_pack == 'youngfamily' ? "Young Family Pack" : order.selected_pack == 'family' ? "Family Pack" : order.selected_pack,
        order_creator: order.created_by
      };
      this.orderService.orderedOrder(params).subscribe(
        // console.log("Order booked in for delivery on " + this.booked_delivery_date + " at " + this.booked_delivery_time);
        (data) => {
          let result = data;
          if (result.status == "success") {
            const logparams = {
                order_id: order.order_id,
                log_entry: "Order booked in for delivery on " + params.booked_delivery_date + " at " + params.booked_delivery_time,
                log_entry_type: "log"
            };
            this.logService.insert(logparams).subscribe(
              (data) => {
                }
            );
            this.getOrderData();
          }
      });
    }

    approvedOrder(order) {
      const params = {
          order_id: order.order_id,
          new_status: "Approved"
      };
      this.orderService.approvedOrder(params).subscribe(
        (data) => {
          let result = data;
          if (result.status == "success") {
            const params = {
                order_id: order.order_id,
                log_entry: "Order Approved",
                log_entry_type: "log"
            };
            this.logService.insert(params).subscribe(
              (data) => {
                }
            );
            this.getOrderData();
          }
      });
    }

    updateOrder(order, newstatus) {
        const params = {
            order_id: order.order_id,
            new_status: newstatus,
            order_denied_text: this.order_denied_text,
            created_by: order.created_by
        };
        this.orderService.updateStatus(params).subscribe(
          (data) => {
            let result = data;
            if (result.status == "success") {
              const params = {
                  order_id: order.order_id,
                  log_entry: "Order " + newstatus,
                  log_entry_type: "log"
              };
              this.logService.insert(params).subscribe(
                (data) => {
                  }
              );
              this.getOrderData();
            }
        });
    }

    ngAfterViewInit() {

    }


}
