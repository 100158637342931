import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import { map, filter, scan } from 'rxjs/operators';


@Injectable()
export class PasswordService {

  private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  private options = new RequestOptions({ headers: this.headers });
  private base = '/api/password';
    public urls = {
    'reset': `${this.base}`
  };

  constructor(private http: Http) {

  };

  reset(params): Observable<any> {
    return this.http.post(this.urls.reset, JSON.stringify(params), this.options);
  }

}
