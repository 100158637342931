import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {

  private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  private options = new RequestOptions({ headers: this.headers });
  private base = '/api/user';
    public urls = {
    'get': this.base,
    'add': this.base,
    'valid': `${this.base}/valid`,
    'delete': `${this.base}/delete`,
    'request': `${this.base}/request`,
    'approve': `${this.base}/approve`,
    'verify': `${this.base}/verify`
  };

  constructor(private http: Http) {

  }

  approve(params): Observable<any> {
    return this.http.put(this.urls.approve, JSON.stringify(params), this.options);
  }

  request(params): Observable<any> {
    return this.http.post(this.urls.request, JSON.stringify(params), this.options);
  }

  createAccount(user): Observable<any> {
    return this.http.post('/api/user/createaccount', JSON.stringify(user), this.options);
  }

  changePassword(user): Observable<any> {
    return this.http.put('/api/user/changepassword', JSON.stringify(user), this.options);
  }

  initialLogin(credentials): Observable<any> {
    return this.http.post('/api/initial-login', JSON.stringify(credentials), this.options);
  }

  login(credentials): Observable<any> {
    return this.http.post('/api/login', JSON.stringify(credentials), this.options).map(res => res.json());
  }

  verify(id): Observable<any> {
    return this.http.get(`${this.urls.verify}/${id}`, this.options).map(res => res.json());
  }

  logout(): Observable<any> {
    return this.http.get('/api/logout');
  }

  getUsers(): Observable<any> {
    return this.http.get(this.base).map(res => res.json());
  }

  addUser(user): Observable<any> {
    return this.http.post(this.base, JSON.stringify(user), this.options);
  }

  getUser(params): Observable<any> {
    return this.http.get(`/api/user/${params.id}`).map(res => res.json());
  }

  editUser(user): Observable<any> {
    return this.http.put(`/api/user/${user.id}`, JSON.stringify(user), this.options);
  }

  deleteUser(user): Observable<any> {
    return this.http.delete(`/api/user/${user.id}`, this.options);
  }

  valid(): Observable<any> {
    return this.http.get(this.urls.valid).map(res => res.json());
  }

}
