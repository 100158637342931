import { JwtModule } from '@auth0/angular-jwt';
export const AUTH_TOKEN = 'token';
export function JwtConfig() {
    return {
        tokenGetter: () => {
            return localStorage.getItem(this.AUTH_TOKEN);
        },
        whitelistedDomains: ['localhost:4200', 'reaching.org.au']
    }
}
