import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { ReportingService } from '../core/services/reporting.service';

import { AuthService } from '../user/services/auth.service';
import * as async from 'async';
import moment from "moment";
import $ from "jquery";
import forEach from 'lodash/forEach';
import * as d3 from 'd3';

@Component({
    styleUrls: ['./getting-started-video.component.scss'],
    templateUrl: './getting-started-video.component.html',
    host: { '(window: resize)': 'onResize($event)' },
})
export class GettingStartedVideoComponent implements OnInit{
    currentUser:any


    constructor(
      private auth: AuthService,
      private reportingService: ReportingService
      ) {
        this.currentUser = auth.currentUser;
    }

    @ViewChild('videoPlayer') videoPlayer: any;

    // video player config
    gettingStartedVideo =
        {
            poster: '../images/videos/REACHING_GETTING_STARTED.png',
            source: '../images/videos/REACHING_GETTING_STARTED.mp4',
            title: 'Getting Started'
        }
    ;

    public chosenId:number = 0;
    public isPlaying = false;
    public isMuted = false;
    public isMaxVol = true;
    public timePercent:number = 0;
    public updateVideoTimeline;

    public style: string = 'smooth';
    chartData = {
        data: [ ],
    };
    public markers: any = {
           border: { width: '1' },
           color: 'rgb(101, 101, 101)',
           size: 4,
           type: 'square'
      };

    ordersLabelContent = (e: any) => {
        return d3.format(",.0f")(e.value)
    }
    public overlay: any = { gradient: 'none' };
    public tooltipBorder: any = { width: '1' };

    public notes: any = {
      label: {
      content: (e: any) => {
          return d3.format(",.0f")(e.value)
      },
      font: "9pt 'Metropolis-Light','Metropolis','Avenir Next','Helvetica Neue',Arial,sans-serif",
      color: 'rgb(101, 101, 101)'
      },
      line: {
          length: 10,
          color: '#D9E4EA'
      },
      icon: {
          type: 'square',
          background: 'transparent',
      },
      position: 'top'
    };

    alltheData = [];
    moment = moment;
    videoModalOpen: boolean = false;

    ngOnInit() {
      if (this.currentUser.role == "admin"){
          this.loadChartData();
      }

    };

    ngAfterViewInit() {

    }

    loadChartData() {
      const self = this;
              async.waterfall([
                  this.getOrdersData.bind(null, this),
              ], function (err, result) {
                  if (err) {
                  } else {
                      self.setupOrdersChart( self );
                  }
                });
    };

    getOrdersData(self, callback) {
      self.alltheData = [];
      let service;
      service = self.reportingService.getOrdersMoMData();
      service.subscribe(
        (data) => {
          // console.log(data)
            self.alltheData = data;
            callback();
        }
      );
    };

    setupOrdersChart(self) {
          let date = moment().format("YYYY").toString();
          let categories: string[] = [];
          let todaysData = { category: 'Today', monthly: 0, total: 0, increase: 0 };

          forEach(self.alltheData, function(item) {
              const formattedDate = moment(item.create_date).format('YYYY');
              if (categories.indexOf(formattedDate) == -1) {
                  categories.push(formattedDate);
              }
              // ==> Log todays user adds
              if (date == moment(item.create_date).format("YYYY").toString()) {
                  todaysData.monthly++;
              }
          });
          categories.sort();
          let categoryData = {};
          forEach(categories, function(item, index) {
              let key = moment(item, 'YYYY').format('YY').toString();
              self.chartData.data.push({ category: key, monthly: 0, total: Number(0), increase: 0 });
              categoryData[key] = 0;
          });
          // ==> Calculate created per day
          forEach(self.alltheData, function(item) {
              let key = moment(item.create_date, 'YYYY').format('YY').toString();
              categoryData[key]++;
          });
          categoryData['Today'] = todaysData.monthly;
          // ==> Loop through an extract values
          let data: number[] = [];
          forEach(categoryData, function(item) {
              data.push(item);
          });
          self.chartData.data.push(todaysData);

          let total = data.map((elem, index) => data.slice(0, index + 1).reduce((a, c) => a + c));

          self.chartData.data.forEach((item, index) => {
          self.chartData.data[index].monthly += data[index];
          self.chartData.data[index].total += total[index];
          self.chartData.data[index].increase += self.chartData.data[index].monthly / self.chartData.data[index].total;
          });
    };

    videoPlay(action: any) {
        this.isPlaying = action;
        if (action) {
            this.updateVideoTimeline = setInterval(() => { this.updateVideoTimelineInterval() }, 50);
            this.videoPlayer.nativeElement.play();
        } else {
            this.videoPlayer.nativeElement.pause();
            clearInterval(this.updateVideoTimeline);
        }
    }

    updateVideoTimelineInterval() {
        let duration = this.videoPlayer.nativeElement.duration,
        current = this.videoPlayer.nativeElement.currentTime;
        this.timePercent = Math.floor(current / duration * 100)
    }

    videoFullscreen() {
        this.videoPlayer.nativeElement.webkitRequestFullScreen();
    }

    videoTimeline(){
        let playAgain = this.isPlaying;
        this.videoPlay(false);
        let duration = this.videoPlayer.nativeElement.duration;
        this.videoPlayer.nativeElement.currentTime = Math.round(duration * (this.timePercent / 100));
        if (playAgain) this.videoPlay(true);
    }

    stopTimeCalc() {
        clearInterval(this.updateVideoTimeline);
    }

    videoVol(action: any) {
        let videoVolume = (this.videoPlayer.nativeElement.volume || 0) * 100;
        if (action === 'up') {
            if (videoVolume === 0) this.isMuted = false;
            videoVolume = (videoVolume + 5) / 100;
            if (videoVolume >= 1) {
                this.isMaxVol = true;
                videoVolume = 1;
            }
        } else if (action === 'down') {
            if (videoVolume <= 100) this.isMaxVol = false;
            videoVolume = (videoVolume - 5) / 100;
            if (videoVolume === 0) this.isMuted = true;
        } else if (!action) {
            videoVolume = 0;
            this.isMuted = true;
        }
        this.videoPlayer.nativeElement.volume = videoVolume.toFixed(2);
    }


}
