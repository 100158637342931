import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { AuthService } from '../user/services/auth.service';
import { OrderService } from '../core/services/order.service';
import { ReportingService } from '../core/services/reporting.service';
import { LogService } from '../core/services/log.service';
import {ClrDatagridSortOrder} from '@clr/angular';
import { DatePipe } from '@angular/common'



@Component({
    styleUrls: ['./order-reporting.component.scss'],
    templateUrl: './order-reporting.component.html',
    // host: { '(window: resize)': 'onResize($event)' },
})
export class OrderReportingComponent {

  data: any[]
  orders = [];
  ordersbycompany = [];
  ordertypes = [];
  ordersbypostcode = [];
  ordersbyreqdelivday = [];
  supervisororders = [];
  currentUser:any;
  isSupervisor: boolean = false;
  allow_reporting: boolean = false;
  supervisorView: boolean = false;

  allorders = [];
  pendingOrders = [];
  approvedOrders = [];
  orderedOrders = [];
  deniedOrders = [];
  orderLog = [];
  new_note: any = "";
  orderNotes = [];
  selectedOrder:any = {};

  all: boolean = false;
  pending: boolean = false;
  approved: boolean = false;
  ordered: boolean = false;
  denied: boolean = false;
  viewLogModal: boolean = false;
  createNoteModal: boolean = false;
  viewNotesModal: boolean = false;
  newNoteNotification: boolean = false;
  deleteOrderModal: boolean = false;
  editOrderModal: boolean = false;

    constructor(
      private orderService: OrderService,
      private reportingService: ReportingService,
      public auth: AuthService,
      public datepipe: DatePipe,
      private logService: LogService
      ) {
        this.currentUser = auth.currentUser;
    }

    ngOnInit() {
      this.currentUser.role == "Supervisor" ? this.isSupervisor = true : this.isSupervisor = false;
      this.currentUser.allow_reporting ? this.allow_reporting = true : this.allow_reporting = false;
      this.getOrders();
      if (this.currentUser.role == "admin"){
          this.getOrdersByCompany();
          this.getOrderTypes();
          this.getOrderPostcodes();
          this.getOrderReqDelivDay();
      }
    };

    ngAfterViewInit() {

    }


    export(component) {
        // this.data = this.allorders;

        const options = component.workbookOptions();

        component.save(options);
    }

    getOrdersByCompany() {
      var self = this;
      this.ordersbycompany = [];
        this.reportingService.getOrdersByCompany().subscribe(
          (data) => {
            self.ordersbycompany = data;
        });
    };

    getOrderTypes() {
      var self = this;
      this.ordersbycompany = [];
        this.reportingService.getOrderTypes().subscribe(
          (data) => {
            self.ordertypes = data;
        });
    };

    getOrderPostcodes() {
      var self = this;
      this.ordersbypostcode = [];
        this.reportingService.getOrderPostcodes().subscribe(
          (data) => {
            self.ordersbypostcode = data;
        });
    }

    getOrderReqDelivDay() {
      var self = this;
      this.ordersbyreqdelivday = [];
        this.reportingService.getOrderReqDelivDay().subscribe(
          (data) => {
            self.ordersbyreqdelivday = data;
        });
    }

    getOrders() {
      var self = this;
      var formattedOrders = [];

      if (this.allow_reporting || this.isSupervisor){
        this.orderService.getSupervisorOrders({company: this.currentUser.company}).subscribe(
          (data) => {
            this.allorders = data;

            for (var i in this.allorders) {
              formattedOrders.push({
                order_id: this.allorders[i].order_id,
                deleted: this.allorders[i].deleted,
                status: this.allorders[i].status,
                create_date: this.datepipe.transform(self.allorders[i].create_date, 'yyyy-MM-dd HH:mm:ss'),
                created_by: this.allorders[i].created_by,
                company: this.allorders[i].company,
                cost_center: this.allorders[i].cost_center,
                samis_code: this.allorders[i].samis_code,
                selected_pack: this.allorders[i].selected_pack,
                recipient: this.allorders[i].recipient,
                mobile: this.allorders[i].mobile,
                address1: this.allorders[i].address1,
                address2: this.allorders[i].address2,
                suburb: this.allorders[i].suburb,
                postcode: this.allorders[i].postcode,
                approved_date: this.datepipe.transform(this.allorders[i].approved_date, 'yyyy-MM-dd HH:mm:ss'),
                ordered_date: this.datepipe.transform(this.allorders[i].ordered_date, 'yyyy-MM-dd HH:mm:ss'),
                booked_delivery_date: this.datepipe.transform(this.allorders[i].booked_delivery_date, 'yyyy-MM-dd HH:mm:ss'),
                booked_delivery_time: this.allorders[i].booked_delivery_time,
                booked_delivery_instructions: this.allorders[i].booked_delivery_instructions
              });
            }
            this.data = formattedOrders;
        });
      } else {
            this.orderService.getAdminOrders().subscribe(
              (data) => {
                this.allorders = data;

                for (var i in this.allorders) {
                  formattedOrders.push({
                    order_id: this.allorders[i].order_id,
                    deleted: this.allorders[i].deleted,
                    status: this.allorders[i].status,
                    create_date: this.datepipe.transform(self.allorders[i].create_date, 'yyyy-MM-dd HH:mm:ss'),
                    created_by: this.allorders[i].created_by,
                    company: this.allorders[i].company,
                    cost_center: this.allorders[i].cost_center,
                    samis_code: this.allorders[i].samis_code,
                    selected_pack: this.allorders[i].selected_pack,
                    recipient: this.allorders[i].recipient,
                    mobile: this.allorders[i].mobile,
                    address1: this.allorders[i].address1,
                    address2: this.allorders[i].address2,
                    suburb: this.allorders[i].suburb,
                    postcode: this.allorders[i].postcode,
                    approved_date: this.datepipe.transform(this.allorders[i].approved_date, 'yyyy-MM-dd HH:mm:ss'),
                    ordered_date: this.datepipe.transform(this.allorders[i].ordered_date, 'yyyy-MM-dd HH:mm:ss'),
                    booked_delivery_date: this.datepipe.transform(this.allorders[i].booked_delivery_date, 'yyyy-MM-dd HH:mm:ss'),
                    booked_delivery_time: this.allorders[i].booked_delivery_time,
                    booked_delivery_instructions: this.allorders[i].booked_delivery_instructions
                  });
                }
                this.data = formattedOrders;
            });
        }
    }




}
