import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';


@Pipe({
    name: 'filterData',
    pure: false
})

export class FilterData implements PipeTransform {
    transform(items:any[], args:string[]):any[] {
        if (typeof items === 'object') {
            var resultArray = [];
            if (args.length === 0) {
                resultArray = items;
            }

            else {
                for (let item of items) {
                    if (item.name != null && item.name.match(new RegExp(''+args, 'i'))) {
                        resultArray.push(item);
                    }
                }
            }

            return resultArray;
        }
        else {
            return null;
        }

    }

}

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      keys.push(key);
    }

    return keys;
  }
}

@Pipe({
    name: 'count'
})


export class CountPipe implements PipeTransform {
  count = 0;
  constructor() {
    console.log('pipe created');
  }
  transform(value: any): any {
    this.count++;
    return value;
  }
}

@Pipe({
  name: 'fullTextSearch',
  pure: false
})



export class FullTextSearchPipe implements PipeTransform {
      transform(items: Array<any>, conditions: {[field: string]: any}): Array<any> {
          return items.filter(item => {
              for (let field in conditions) {
                  if (item[field] !== conditions[field]) {
                      return false;
                  }
              }
              return true;
          });
      }
}


@Pipe({
  name: 'unique',
  pure: false
})

export class UniquePipe implements PipeTransform {
    transform(value: any, item): any{
        if(value!== undefined && value!== null){
            return _.uniqBy(value, item);
        }
        return value;
    }
}
