import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class CompaniesService {

  private base = '/api/companies';
  public urls = {
    'getCompanies': `${this.base}/getCompanies`,
    'deleteCompany': `${this.base}/deleteCompany`,
    'addCompany': `${this.base}/addCompany`,
    'editCompany': `${this.base}/editCompany`
  };

  constructor(private http: Http) {

  };

  getCompanies(): Observable<any> {
    return this.http.get(this.urls.getCompanies, {}).map(res => res.json());
  }

  deleteCompany(company): Observable<any> {
    return this.http.post(this.urls.deleteCompany, {'params': company}).map(res => res.json());
  };

  addCompany(company): Observable<any> {
    return this.http.post(this.urls.addCompany, {'params': company}).map(res => res.json());
  }

  editCompany(company): Observable<any> {
    return this.http.post(this.urls.editCompany, {'params': company}).map(res => res.json());
  }

}
