

import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { AuthService } from '../../user/services/auth.service';
import { UserService } from '../../user/services/user.service';
import { CompaniesService } from '../../core/services/companies.service';
import { formatDate } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';


@Component({
    styleUrls: ['./admin-companies.component.scss'],
    templateUrl: './admin-companies.component.html',
    host: { '(window: resize)': 'onResize($event)' },
})
export class AdminCompaniesComponent {
  @ViewChild('companyForm') form;
  companies = [];
  currentCompany:any = [];
  newCompanyName = "";
  // currentCompany.company_name = "";
  processing = false;
  showSuccess = false;
  showError = false;
  message = '';

  modals = {
      edit: {
          opened: false,
          processing: false
      },
      create: {
          opened: false,
          processing: false
      },
      delete: {
          opened: false,
          processing: false
      }
  }

    constructor(
      private companiesService: CompaniesService,
      public auth: AuthService) {
    }

    ngOnInit() {
      this.getCompanies();
    };

    getCompanies(){
      this.companies = [];

      this.companiesService.getCompanies().subscribe(
        (data) => {
          this.companies = data;
      });
    }

    ngOnChanges(changes: any) {

    }

    editModalOpen(company) {
        this.modals.edit.opened = true;
        this.currentCompany = company;
    };

    deleteModalOpen(company) {
        this.modals.delete.opened = true;
        this.currentCompany = company;
    };

    save() {
      this.processing = true;
      const companyUpdate = this.currentCompany;

      this.companiesService.editCompany(companyUpdate).subscribe(
        data => {
          this.message = 'Company Information has been successfully updated.';
          this.showSuccess = true;
          this.form['form'].markAsPristine();
        },
        error => {
          this.message = 'Company Information could not be updated.';
          this.showError = true;
          this.processing = false;
        },
        () => {
          this.processing = false;
          this.hideMessages();
        }
      );
    }

    newCompanySave(newName) {
      this.processing = true;
      this.companiesService.addCompany(newName).subscribe(
        data => {
          this.message = 'Company has been successfully created.';
          this.showSuccess = true;
          this.form['form'].markAsPristine();
          this.modals.create.opened = false;
          this.getCompanies();
        },
        error => {
          this.message = 'Company could not be created.';
          this.showError = true;
          this.processing = false;
        },
        () => {
          this.processing = false;
          this.hideMessages();
        }
      );
    }

    delete(company) {
      this.modals.delete.processing = true;
      this.companiesService.deleteCompany(company).subscribe();
      setTimeout(() => {
        this.getCompanies();
        this.modals.delete.processing = false;
        this.modals.delete.opened = false;
        this.currentCompany = {};
      }, 2000);
    }

    hideMessages() {
      setTimeout(() => {
        this.processing = false;
        this.showSuccess = false;
        this.showError = false;
        this.message = '';
      }, 3000);
    };

}
