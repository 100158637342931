import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PasswordService } from '../../../user/services/password.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-password-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  createAccountForm: FormGroup;

  email = new FormControl('', [Validators.required,
                               Validators.minLength(3),
                               Validators.maxLength(100)]);

  processing = false;

  constructor(private formBuilder: FormBuilder,
              private passwordService: PasswordService) { }

  ngOnInit() {
    this.createAccountForm = this.formBuilder.group({
      email: this.email
    });
  };

  alerts = {
    failure: {
      show: false,
      message: ''
    },
    success:  {
      show: false,
      message: ''
    }
  };

  save() {
    this.alerts.failure.show = false;
    this.processing = true;
    this.passwordService.reset(this.createAccountForm.value).subscribe(      
      res => {
        this.alerts.success.show = true;
        this.alerts.success.message = 'Password was successfully reset. Please check your email.';
      },
      error => {
        this.alerts.failure.show = true;
        this.alerts.failure.message = error.status == "400" ? 'Email was not found' : (_.isEmpty(error._body) ? 'Unknown error' : JSON.parse(error._body));
        this.processing = false;
      }
    );
  }
}
