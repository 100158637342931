import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { ClarityIcons } from "@clr/icons";
import { AuthGuardLogin } from '../user/services/auth-guard-login.service';



@NgModule({
    imports: [
        ClarityModule,
        CommonModule,
        FormsModule
    ],
    exports:      [
        FormsModule,
        ClarityModule,
        ReactiveFormsModule
    ],
    declarations: [
    ],
    providers: [
        AuthGuardLogin
    ]
})
export class NewOrderModule {

}
