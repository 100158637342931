import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class LogService {

  private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  private options = new RequestOptions({ headers: this.headers });
  private base = '/api/log';
  public urls = {
    'getLog': `${this.base}/getLog`,
    'insert': `${this.base}/insert`,
  };

  constructor(private http: Http) {

  };

  getLog(params): Observable<any> {
    return this.http.get(this.urls.getLog, {params: params}).map(res => res.json());
  }

  insert(params): Observable<any> {
    return this.http.post(this.urls.insert, params, this.options).map(res => res.json());
  }

}
