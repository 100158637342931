import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AuthService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  loginForm: FormGroup;
  username = new FormControl('', [Validators.required,
                                  Validators.minLength(2),
                                  Validators.maxLength(100)]);

  email = new FormControl('', [Validators.required,
                               Validators.minLength(3),
                               Validators.maxLength(100)]);

  password = new FormControl('', [Validators.required,
                                  Validators.minLength(6)]);

  showError = false;
  error: String;

  ngOnInit() {
    if (this.auth.loggedIn) {
      this.router.navigate(['/home']);
    }
    this.loginForm = this.formBuilder.group({
      username: this.email,
      email: this.email,
      password: this.password
    });
  }

  setClassEmail() {
    return { 'has-danger': !this.email.pristine && !this.email.valid };
  }

  setClassPassword() {
    return { 'has-danger': !this.password.pristine && !this.password.valid };
  }

  login() {
    this.error = '';
    this.showError = false;
    this.auth.login(this.loginForm.value).subscribe(
      res => {
        this.router.navigate(['/home']);
      },
      error => {
        if (error._body) {
          const message = JSON.parse(error._body);
          if (message.msg) {
            this.error = message.msg;
          } else {
            this.error = 'Invalid login';
          }
        }
        this.showError = true;
      }
    );
  }

}
