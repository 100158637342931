import { NgModule }            from '@angular/core';
import { RouterModule }        from '@angular/router';
import { HomeComponent } from "../home/home.component";
import { GettingStartedVideoComponent } from "../getting-started-video/getting-started-video.component";
import { OrdersComponent } from "../orders/orders.component";
import { OrderReportingComponent } from "../reporting/order-reporting.component"
import { SamisReportingComponent } from "../samis-reporting/samis-reporting.component"
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardLogin } from '../user/services/auth-guard-login.service';

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'home', component: HomeComponent, canActivate: [AuthGuardLogin] },
    { path: 'getting-started-video', component: GettingStartedVideoComponent }, // no log in required
    { path: 'orders', component: OrdersComponent, canActivate: [AuthGuardLogin] },
    { path: 'order-reporting', component: OrderReportingComponent, canActivate: [AuthGuardLogin] },
    { path: 'samis-reporting', component: SamisReportingComponent, canActivate: [AuthGuardLogin] },
    { path: 'notfound', component: NotFoundComponent },
  ])],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
