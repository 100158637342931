import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { AuthService } from '../user/services/auth.service';
import { OrderService } from '../core/services/order.service';
import { LogService } from '../core/services/log.service';
import {ClrDatagridSortOrder} from '@clr/angular';


@Component({
    styleUrls: ['./orders.component.scss'],
    templateUrl: './orders.component.html',
    host: { '(window: resize)': 'onResize($event)' },
})
export class OrdersComponent {

  orders = [];
  supervisororders = [];
  currentUser:any;
  isSupervisor: boolean = false;
  supervisorView: boolean = false;
  costCenterView: boolean = false;

  allorders = [];
  pendingOrders = [];
  approvedOrders = [];
  orderedOrders = [];
  deniedOrders = [];
  orderLog = [];
  new_note: any = "";
  orderNotes = [];
  selectedOrder:any = {};

  all: boolean = false;
  pending: boolean = false;
  approved: boolean = false;
  ordered: boolean = false;
  denied: boolean = false;
  viewLogModal: boolean = false;
  createNoteModal: boolean = false;
  viewNotesModal: boolean = false;
  newNoteNotification: boolean = false;
  deleteOrderModal: boolean = false;
  editOrderModal: boolean = false;

    constructor(
      private orderService: OrderService,
      public auth: AuthService,
      private logService: LogService
      ) {
        this.currentUser = auth.currentUser;
    }

    ngOnInit() {
      if (this.currentUser.role == "Supervisor") {
        this.isSupervisor = true;
        this.getSupervisorOrders();
      } else if (this.currentUser.cost_center){
        this.costCenterView = true;
        this.getCostCenterOrders();
      } else {
        this.getOrders();
      }
    };

    ngAfterViewInit() {

    }

    getOrders() {
      this.supervisorView = false;
      this.costCenterView = false;
      this.orders = [];
      this.allorders = [];
      this.pendingOrders = [];
      this.approvedOrders = [];
      this.orderedOrders = [];
      this.deniedOrders = [];

      this.orderService.getOrders().subscribe(
        (data) => {
          this.allorders = data;
          for (let order of this.allorders) {
            if (order.status == "Pending"){this.pendingOrders.push(order)}
            if (order.status == "Approved"){this.approvedOrders.push(order)};
            if (order.status == "Ordered"){this.orderedOrders.push(order)};
            if (order.status == "Denied"){this.deniedOrders.push(order)};
          };
          // for users we show all by default
          this.filterOrders('All');
      });
    }

    viewLog(order) {
      this.orderLog = [];
      const params = {
          order_id: order.order_id
      };
      this.logService.getLog(params).subscribe(
        (data) => {
          this.orderLog = data;
          }
      );
      this.viewLogModal = true;
    }

    viewNotes(order) {
      this.selectedOrder = order;
      this.orderLog = [];
      this.orderNotes = []
      const params = {
          order_id: order.order_id
      };
      this.logService.getLog(params).subscribe(
        (data) => {
            this.orderLog = data;
            for (let entry of this.orderLog) {
                if (entry.log_entry_type == "note") {
                  this.orderNotes.push(entry);
                }
            }
         }
      );
      this.viewNotesModal = true;
    }

    createNote(order) {
      this.selectedOrder = order;
      this.createNoteModal = true;
    }

    clearAlert(order) {
      this.selectedOrder = order;
      this.new_note = "New note alert cleared";
      this.sendNote();
    }

    sendNote() {
      const params = {
          order_id: this.selectedOrder.order_id,
          log_entry: this.new_note,
          log_entry_type: "note",
          order_creator: this.selectedOrder.created_by
      };
      this.logService.insert(params).subscribe(
        (data) => {
          }
      );
      this.new_note = [];
      this.getOrders();
      this.viewNotesModal = false;
      this.createNoteModal = false;
    }

    editOrder(order) {
      // console.log("Edit Order ID: ", order)
      this.selectedOrder = order;
      this.editOrderModal = true;
    }

    editOrderFinished() {

      this.orderService.updateOrder(this.selectedOrder).subscribe(
        (data) => {
            const logparams = {
                order_id: this.selectedOrder.order_id,
                log_entry: "Edit Order Completed",
                log_entry_type: "log"
            };
            this.logService.insert(logparams).subscribe(
              (data) => {
                }
            );
            this.getOrders();
            this.selectedOrder = {};
      });
    }

    cancelEditOrder() {
      this.selectedOrder = {};
    }



    deleteOrder(order) {
      this.selectedOrder = order;
      this.deleteOrderModal = true;
      // console.log("Delete Order ID: ", order)
    }

    confirmDeleteOrder() {

      const params = {
          order_id: this.selectedOrder.order_id
      };

      this.orderService.delete(params).subscribe(
          data => {
            this.deleteOrderModal = false;
            this.getOrders();
            this.selectedOrder = {};
          },
          error => {
            this.deleteOrderModal = false;
            this.selectedOrder = {};
          }
      );
    }

    getSupervisorOrders() {
      this.supervisorView = true;
      this.orders = [];
      this.allorders = [];
      this.pendingOrders = [];
      this.approvedOrders = [];
      this.orderedOrders = [];
      this.deniedOrders = [];

      const params = {
          role: this.currentUser.role,
          company: this.currentUser.company
      };
      this.orderService.getSupervisorOrders(params).subscribe(
        (data) => {
          this.allorders = data;
          for (let order of this.allorders) {
            if (order.status == "Pending"){this.pendingOrders.push(order)}
            if (order.status == "Approved"){this.approvedOrders.push(order)};
            if (order.status == "Ordered"){this.orderedOrders.push(order)};
            if (order.status == "Denied"){this.deniedOrders.push(order)};
          };
          // for supervisor we show pending by default
          this.filterOrders('All');
      });
    }

    getCostCenterOrders() {
      this.costCenterView = true;
      this.orders = [];
      this.allorders = [];
      this.pendingOrders = [];
      this.approvedOrders = [];
      this.orderedOrders = [];
      this.deniedOrders = [];

      const params = {
          role: this.currentUser.role,
          company: this.currentUser.company,
          cost_center: this.currentUser.cost_center
      };
      this.orderService.getCostCenterOrders(params).subscribe(
        (data) => {
          this.allorders = data;
          for (let order of this.allorders) {
            if (order.status == "Pending"){this.pendingOrders.push(order)}
            if (order.status == "Approved"){this.approvedOrders.push(order)};
            if (order.status == "Ordered"){this.orderedOrders.push(order)};
            if (order.status == "Denied"){this.deniedOrders.push(order)};
          };
          // for supervisor we show pending by default
          this.filterOrders('All');
      });
    }

    filterOrders(filter){
      this.all = false;
      this.pending = false;
      this.approved = false;
      this.ordered = false;
      this.denied = false;
      switch(filter){
        case 'All': {
          this.orders = this.allorders;
          this.all = true;
          break;
        }
        case "Pending": {
          this.orders = this.pendingOrders;
          this.pending = true;
          break;
        }
        case "Approved": {
          this.orders = this.approvedOrders;
          this.approved = true;
          break;
        }
        case "Ordered": {
          this.orders = this.orderedOrders;
          this.ordered = true;
          break;
        }
        case "Denied": {
          this.orders = this.deniedOrders;
          this.denied = true;
          break;
        }
      }
    }


}
