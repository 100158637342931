import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { AlertsDangerComponent } from './alerts/danger/danger.component';
import { AlertsSuccessComponent } from './alerts/success/success.component';
import { Utilities } from './utilities';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    ChartsModule,
  ],
  declarations: [
    AlertsDangerComponent,
    AlertsSuccessComponent,
  ],
  exports: [
    CommonModule,
    ClarityModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    AlertsDangerComponent,
    AlertsSuccessComponent,
  ],
  providers: [
  ]
})
export class SharedModule {

}
