import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, EmailValidator } from '@angular/forms';
import { AuthService } from '../../user/services/auth.service';
import { UserService } from '../../user/services/user.service';

import * as moment from 'moment';
import * as randomstring from 'randomstring';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  createAccountForm: FormGroup;
  username = new FormControl('');

  email = new FormControl('', [Validators.required,
                               Validators.minLength(4),
                               Validators.maxLength(100)]);

  firstname = new FormControl('', [Validators.maxLength(150)]);

  lastname = new FormControl('', [Validators.maxLength(150)]);

  role = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  title = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  company = new FormControl('', [Validators.required,
                                  Validators.minLength(3),
                                  Validators.maxLength(100)]);

  approvedby = new FormControl('', [Validators.minLength(3),
                                    Validators.maxLength(100)]);

  cost_center = new FormControl('', [Validators.maxLength(24)]);

  isModal = false;

  selectedCompany: any;
  selectedRole: any;

  @Input() createUsers = false;
  @Input() companies = [];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private userService: UserService,
              public auth: AuthService) { }

  ngOnInit() {
    this.createAccountForm = this.formBuilder.group({
      username: this.email,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      role: this.role,
      title: this.title,
      company: this.company,
      cost_center: this.cost_center,
      approvedby: this.auth.currentUser.email
    });
  }

  showError = false;
  error: String;
  processing = false;
  showSuccess = false;
  message = '';


  setClassEmail() {
    return { 'has-danger': !this.email.pristine && !this.email.valid };
  }

  createAccount() {
    this.error = '';
    this.showError = false;
    this.userService.createAccount(this.createAccountForm.value).subscribe(
      res => {
        this.message = 'User account has been successfully created.';
        this.showSuccess = true;
        this.createAccountForm.reset();
      },
      error => {
        this.message = error.status == "400" ? 'Login is already in use.' : 'User account could not be created.';
        this.showError = true;
      },
      () => {
        this.processing = false;
        this.hideMessages();
      }
    );
  }

  hideMessages() {
    setTimeout(() => {
      this.showSuccess = false;
      this.showError = false;
      this.message = '';
    }, 3000);
  };

}
