import { AuthService } from '../user/services/auth.service';
import { Component, ViewChild, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrWizard } from '@clr/angular';
import { ClarityIcons } from "@clr/icons";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import { OrderService } from '../core/services/order.service';
import { LogService } from '../core/services/log.service';


import * as _ from 'lodash';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})

export class NewOrderComponent implements OnInit{
  @ViewChild("wizard") wizard: ClrWizard;
  open: boolean = true;

  isLoading = false;

  order = {
        dw_user_key: 0,
        createdby: "",
        selectedpack: "",
        deliveryday: "",
        deliverytime1: "",
        deliverytime2: "",
        deliverytime3: "",
        name: "",
        address1: "",
        address2: "",
        suburb: "",
        postcode: "",
        delivInstructions: "",
        mobile: "",
        samis_code: ""
    };

  orderid: number;
  ordercomplete: boolean = false;

  stvdpoptions: boolean = false;
  salvoptions: boolean = false;

  constructor (
                public auth: AuthService,
                private orderService: OrderService,
                private logService: LogService,
                private router: Router ) {}

  ngOnInit(){
    this.order.dw_user_key = this.auth.currentUser.dw_user_key;
    // console.log(this.auth.currentUser.dw_user_key)
    this.order.createdby = this.auth.currentUser.username;
  }

  packSelected(){
    if (this.order.selectedpack == 'streetsupportpack'){
      this.stvdpoptions = true;
      this.salvoptions = false;
    } else {
      this.stvdpoptions = false;
      this.salvoptions = true;
    }

  }

  cancelWizard(){
    this.router.navigate(['/home']);
  }

  wizardfinished(){
    // console.log(this.order)
    let order = {};

    if (this.stvdpoptions){
      this.order["address1"] = "Pickup";
      this.order["address2"] = "Pickup";
      this.order["postcode"] = '0000';
    }

    order = this.order
    // console.log(order)
    this.orderService.insert(order).subscribe(
      (data) => {
        // console.log(data)
        if (data.order_id) {
          this.orderid = data.order_id;
          const params = {
              order_id: this.orderid,
              log_entry: "Order created",
              log_entry_type: "log"
          };
          this.logService.insert(params).subscribe(
            (data) => {
              }
          );
          this.ordercomplete = true;
        }
    });
  }

  orderComplete(){
    this.ordercomplete = false;
    this.router.navigate(['/orders']);
  }

}
