import { NgModule }            from '@angular/core';
import { RouterModule }        from '@angular/router';
import { HomeComponent }    from '../home/home.component';
import { NewOrderComponent }    from '../new-order/new-order.component';
import { LoginComponent }    from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RequestComponent } from './request/request.component';
import { PasswordResetComponent } from './password/reset/reset.component';
import { UserAccountComponent } from './account/account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InitialLoginComponent }    from './initial-login/initial-login.component';
import { AuthGuardLogin } from '../user/services/auth-guard-login.service';

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'home', component: HomeComponent },
    { path: 'new-order', component: NewOrderComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'request', component: RequestComponent },
    { path: 'account/:id', component: UserAccountComponent, canActivate: [AuthGuardLogin] },
    { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuardLogin] },
    { path: 'initial-login', component: InitialLoginComponent },
    { path: 'password/reset', component: PasswordResetComponent }
  ])],
  exports: [RouterModule]
})
export class UserRoutingModule {}
