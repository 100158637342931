import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core";
import { trigger, state, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { AuthService } from '../user/services/auth.service';
import { OrderService } from '../core/services/order.service';
import { ReportingService } from '../core/services/reporting.service';
import { LogService } from '../core/services/log.service';
import {ClrDatagridSortOrder} from '@clr/angular';
import { DatePipe } from '@angular/common'

import moment from "moment";

@Component({
    styleUrls: ['./samis-reporting.component.scss'],
    templateUrl: './samis-reporting.component.html',
    // host: { '(window: resize)': 'onResize($event)' },
})
export class SamisReportingComponent {

  data: any[]
  samisOrders = [];
  ordertypes = [];
  ordersbypostcode = [];
  ordersbyreqdelivday = [];
  supervisororders = [];
  currentUser:any;
  isSupervisor: boolean = false;
  allow_reporting: boolean = false;
  supervisorView: boolean = false;
  samisOrdersLoaded: boolean = false;

  days: number = 0;
  timeframe: number;

  customDates: boolean = false;
  startDate: any;
  endDate: any;


    constructor(
      private orderService: OrderService,
      private reportingService: ReportingService,
      public auth: AuthService,
      public datepipe: DatePipe,
      private logService: LogService
      ) {
        this.currentUser = auth.currentUser;
    }

    ngOnInit() {
      this.startDate = new Date();
      this.endDate = new Date();

      this.currentUser.role == "Supervisor" ? this.isSupervisor = true : this.isSupervisor = false;
      this.currentUser.allow_reporting ? this.allow_reporting = true : this.allow_reporting = false;

      this.selectCustomDays(7);
    };

    ngAfterViewInit() {

    }


    export(component) {
        // this.data = this.allorders;

        const options = component.workbookOptions();

        component.save(options);
    }

    getSamisOrders() {
      var self = this;
      this.samisOrders = [];
      this.samisOrdersLoaded = false;

      if (this.customDates){
          const dateB = moment(this.startDate);
          const dateC = moment(this.endDate);
          this.timeframe = dateB.diff(dateC, 'days') * -1;
      };

      const params = {
        startDate: new Date(this.startDate).toISOString(),
        endDate: new Date(this.endDate).toISOString()
      };
      this.reportingService.getSamisOrders(params).subscribe(
        (data) => {
          self.samisOrders = data;
          self.samisOrdersLoaded = true;
        },
        error =>{
      });

    };

    selectCustomDates() {
      this.days = 0;
      if (this.customDates){
        this.customDates = false
        this.getSamisOrders();
      } else {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 7);
        this.startDate = currentDate;
        this.endDate = new Date();
        this.customDates = true;
        this.getSamisOrders();
      }
    }

  selectCustomDays(days){
    if (days == this.days){
      this.days = 0;
      this.startDate =  new Date();
      this.endDate = new Date();
    } else {
      this.days = days;
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - this.days);
      this.startDate = currentDate;
      this.endDate = new Date();

    }

    this.getSamisOrders();
  }



}
