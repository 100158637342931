import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import * as _ from 'lodash';

@Injectable()
export class AuthGuardLogin implements CanActivate {

  constructor( public auth: AuthService, private router: Router ) {

  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    if (!this.auth.loggedIn) {
      this.router.navigate(['/login']);
    }
    return this.auth.loggedIn;
  }
}
