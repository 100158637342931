
import { Component, ChangeDetectorRef, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, animate, transition, query } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from './user/services/auth.service';
import { UserService } from './user/services/user.service';
import { ClarityIcons } from "@clr/icons";

import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class AppComponent  {
    title: string = 'REACHING';

    constructor(public auth: AuthService,
                private userService: UserService,
                private cdr: ChangeDetectorRef,
                private router: Router) {
                this.currentUser = auth.currentUser;
      }

    currentUser:any;



    ngOnInit() {
// console.log(this.currentUser)
    };


}
