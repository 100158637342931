import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class ReportingService {

  private headers = new Headers({ 'Content-Type': 'application/json', 'charset': 'UTF-8' });
  // private options = new RequestOptions({ headers: this.headers });
  private options = { map: false };
  // private multiHeaders = new Headers({ 'Accept': 'application/json' });
  // private multiOptions = new RequestOptions({ headers: this.multiHeaders });
  private base = '/api/reporting';
  public urls = {
    'getOrdersByCompany': `${this.base}/getOrdersByCompany`,
    'getOrderTypes': `${this.base}/getOrderTypes`,
    'getOrderPostcodes': `${this.base}/getOrderPostcodes`,
    'getOrderReqDelivDay': `${this.base}/getOrderReqDelivDay`,
    'getOrdersMoMData': `${this.base}/getOrdersMoMData`,
    'getSamisOrders': `${this.base}/getSamisOrders`

  };

  constructor(private http: Http) {

  };

  getOrdersByCompany(): Observable<any> {
    return this.http.get(this.urls.getOrdersByCompany, {}).map(res => res.json());
  }

  getOrderTypes(): Observable<any> {
    return this.http.get(this.urls.getOrderTypes, {}).map(res => res.json());
  }

  getOrderPostcodes(): Observable<any> {
    return this.http.get(this.urls.getOrderPostcodes, {}).map(res => res.json());
  }

  getOrderReqDelivDay(): Observable<any> {
    return this.http.get(this.urls.getOrderReqDelivDay, {}).map(res => res.json());
  }

  getOrdersMoMData(): Observable<any> {
    return this.http.get(this.urls.getOrdersMoMData, {}).map(res => res.json());
  }

  getSamisOrders( params ): Observable<any> {
    return this.http.get(this.urls.getSamisOrders, {params}).map(res => res.json());
  }
  //
  // getSamisOrders( params ): Observable<any> {
  //   return this.http.get(this.urls.getSamisOrders, {params}, this.options);
  // };

}
