import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UniquePipe } from './customfilter.pipe';
import { FilterData } from './customfilter.pipe';
import { FullTextSearchPipe } from './customfilter.pipe';
import { KeysPipe } from './customfilter.pipe';
import { CountPipe } from './customfilter.pipe';

import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { UserModule } from '../user/user.module';

import { AdminComponent } from './admin.component';
import { AdminUserAccountComponent } from './admin-account/admin-account.component';
import { AdminOrdersComponent } from './admin-orders/admin-orders.component';
import { AdminCompaniesComponent } from './admin-companies/admin-companies.component';

import { CreateAccountComponent } from './create-account/create-account.component';
import { UserService } from '../user/services/user.service';

@NgModule({
    imports: [
        CoreModule,
        SharedModule,
        AdminRoutingModule,
        UserModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        AdminComponent,
        AdminUserAccountComponent,
        AdminOrdersComponent,
        AdminCompaniesComponent,
        CreateAccountComponent,
        UniquePipe,
        FullTextSearchPipe,
        FilterData,
        KeysPipe,
        CountPipe,
    ],
    providers: [
        UserService,
        [DatePipe]
    ]
})
export class AdminModule { }
