import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('accountForm') form;
  @Input() id: string;
  @Input() isModal: boolean = false;

  user = {};
  processing = false;
  isLoading = true;
  showSuccess = false;
  showError = false;
  message = '';
  currentpassword = '';
  newpassword = '';
  confirmpassword = '';

  constructor(private auth: AuthService,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.getUser(this.auth.currentUser);
  }

  ngOnChanges(changes: any) {
    // console.log(this.id);
    if (changes.id) {
      if (changes.id.previousValue != changes.id.currentValue && !_.isEmpty(changes.id.currentValue)) {
        this.getUser(this.id);
        console.log(this.id);
      }
    }
  };

  getUser(user) {
    this.userService.getUser(user).subscribe(
      data => {
        this.user = data;
         // console.log(data);
      },
      error => {
        this.message = 'User Information could not be loaded.';
        this.showError = true;
      },
      () => this.isLoading = false
    );
  };

  save(user) {
    this.processing = true;

    this.user['currentpassword'] = this.currentpassword;
    this.user['newpassword'] = this.newpassword;
    this.user['confirmpassword'] = this.confirmpassword;

    if ( this.currentpassword != this.newpassword ) {
      if ( this.newpassword == this.confirmpassword ) {
        this.userService.changePassword(user).subscribe(
          data => {
            this.message = 'User Information has been successfully updated.';
            this.showSuccess = true;
            this.form['form']._pristine = true;
            this.form['form'].reset();

            setTimeout(() => {
              this.showSuccess = false;
              this.showError = false;
              this.message = '';
              this.router.navigate(['/logout']);
            }, 1000);

          },
          error => {
            this.message = 'User Information could not be updated.';
            this.showError = true;
            console.log(this.message);
          },
          () => {
            this.processing = false;
            this.hideMessages();
          }
        );
      } else {
        this.message = "New passwords don't match";
        this.showError = true;
        this.newpassword = '';
        this.confirmpassword = '';
        this.hideMessages();
      }
    } else {
      this.message = "Please choose a new password";
      this.showError = true;
      this.hideMessages();
    }

    // console.log(user);

  }

  hideMessages() {
    setTimeout(() => {
      this.showSuccess = false;
      this.showError = false;
      this.message = '';
    }, 3000);
  };
}
