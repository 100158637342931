import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerts-danger',
  templateUrl: './danger.component.html',
  styleUrls: ['./danger.component.scss']
})
export class AlertsDangerComponent implements OnInit {

  constructor() {

  }

  @Input() show: boolean = false;
  @Input() message: string = '';

  ngOnInit() {

  }
}

