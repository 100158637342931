import { NgModule }            from '@angular/core';
import { RouterModule }        from '@angular/router';

import { AdminComponent } from './admin.component';
import { AdminUserAccountComponent } from './admin-account/admin-account.component';
import { AdminOrdersComponent } from './admin-orders/admin-orders.component';
import { AdminCompaniesComponent } from './admin-companies/admin-companies.component';
import { AuthGuardAdmin } from '../user/services/auth-guard-admin.service';
import { CreateAccountComponent } from './create-account/create-account.component';

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuardAdmin] },
    { path: 'admin-account', component: AdminUserAccountComponent, canActivate: [AuthGuardAdmin] },
    { path: 'admin-orders', component: AdminOrdersComponent, canActivate: [AuthGuardAdmin] },
    { path: 'admin-companies', component: AdminCompaniesComponent, canActivate: [AuthGuardAdmin] },
    { path: 'create-account', component: CreateAccountComponent, canActivate: [AuthGuardAdmin] },
  ])],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
